define(['accessibilityAnnouncer'], function(accessibilityAnnouncer) {

  const responsiveAccountMessage = () => {
    const component = {};

    /** script start **/

    document.querySelectorAll('.discussion-detail').forEach(el => {
      el.style.display = 'none';
    });
    document.querySelectorAll('.toggleDiscussion').forEach(el => {
      el.textContent = window.siteObj.props.toggleDiscussion.viewBtn;
      el.addEventListener('click', () => {
        const discussionId = el.getAttribute('rel');
        const discussionEl = document.querySelector('#discussion-' + discussionId);

        if (el.getAttribute('aria-expanded') === 'true') {
          discussionEl.style.display = 'none';
          el.setAttribute('aria-expanded', 'false');
          el.textContent = window.siteObj.props.toggleDiscussion.viewBtn;
        } else {
          discussionEl.style.display = 'block';
          el.setAttribute('aria-expanded', 'true');
          el.textContent = window.siteObj.props.toggleDiscussion.hideBtn;
          const messageCardTitle = el.parentElement.querySelector('.responsiveAccountMessage_messageCard_title');
          messageCardTitle.focus();
          messageCardTitle.scrollIntoView({block: 'start'});
          const westendHeader = document.querySelector('.westendHeader');
          westendHeader && window.scrollBy(0, -westendHeader.clientHeight);
        }
      });
    });

    // customer services textarea limit announcement
    document.querySelectorAll('.responsiveAccountMessage_messageCard_textArea').forEach(el => {
      el.addEventListener('keyup', e => {
        if (e.target.valueOf().length === 1000) {
          accessibilityAnnouncer.announce('polite', window.siteObj.props.customerServiceTextArea.limitAnnouncement);
        }
      });
    });

    /** script end **/


    return component;
  };
  return responsiveAccountMessage;

});
